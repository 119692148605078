import React from "react";
import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_CATEGORY
} from "@constants";
import { CustomMadePageBS } from "@style-variables";
import { escapeReact } from "@utils/react";
import { joinNonEmptyStrings } from "@utils/strings";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.CustomMade;

  const escapeReactRoute = str => escapeReact(str, pathfinder, false, true);

  const getRoutePathByRouteName = (name, slug) =>
    pathfinder.get({
      name,
      params: { categoryId: slug }
    });

  const getRoutePathBySelectorType = (selectorType, slug) =>
    findPathBySelectorType(pathfinder, selectorType, slug);

  return {
    lead: {
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT),
      titleAs: "h1",
      className: joinNonEmptyStrings(CustomMadePageBS, "lead")
    },
    orderingSteps: {
      hasButton: false,
      title: _i18n.orderingSteps.TITLE,
      className: joinNonEmptyStrings(CustomMadePageBS, "ordering-steps"),
      items: _i18n.orderingSteps.items.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        footerArrow: false,
        img: item.IMG
          ? {
              src: imgParams.imgDir + "/pages/custom-made/".concat(item.IMG),
              importance: "high",
              cloudinary: imgParams.cloudinary,
              sizes: {
                mobilePortrait: 344,
                mobileLandscape: 420,
                tabletPortrait: 254,
                tabletLandscape: 255,
                desktop: 315
              },
              aspect: 1.419,
              version: true,
              title: item.TITLE
            }
          : null
      }))
    },
    customProductCategories: {
      hasButton: false,
      title: _i18n.customProductCategories.TITLE,
      className: joinNonEmptyStrings(CustomMadePageBS, "product-categories"),
      items: _i18n.customProductCategories.items.map((item, i) => ({
        img: item.IMG
          ? {
              src: imgParams.imgDir + "/pages/custom-made/".concat(item.IMG),
              importance: "high",
              cloudinary: imgParams.cloudinary,
              sizes: {
                mobilePortrait: 336,
                mobileLandscape: 500,
                tabletPortrait: 336,
                tabletLandscape: 456,
                desktop: 543
              },
              aspect: 1,
              version: true,
              title: item.TITLE
            }
          : null,
        header: <h3>{item.TITLE}</h3>,
        text: item.TEXT,
        url: getRoutePathBySelectorType(
          PRODUCT_SELECTOR_TYPE_CATEGORY,
          item.CATEGORY_SLUG
        ),
        footerArrow: false
      }))
    },
    costAndDelivery: {
      lead: {
        title: _i18n.costAndDelivery.lead.TITLE,
        text: escapeReactRoute(_i18n.costAndDelivery.lead.TEXT),
        titleAs: "h2",
        className: joinNonEmptyStrings(CustomMadePageBS, "cost-delivery")
      },
      table: {
        visible: _i18n.costAndDelivery.table.visible,
        header: (_i18n.costAndDelivery.table.header || []).map(
          item => item.TITLE
        ),
        rows: (_i18n.costAndDelivery.table.rows || []).map(item =>
          item.columns.map(col => col.TEXT)
        ),
        headVariant: "active",
        headerAlign: "center",
        textAlign: "left",
        className: joinNonEmptyStrings(
          CustomMadePageBS,
          "cost-delivery price-table"
        ),
        bordered: true,
        borderless: true,
        hover: true,
        responsive: "md",
        small: false,
        striped: false,
        //variant: "light",
        firstColAsHead: false,
        smallMediaBreakpoint: "smDown",
        defaultMediaBreakpoint: "smUp",
        caption: _i18n.costAndDelivery.table.CAPTION // https://getbootstrap.com/docs/4.5/content/tables/#captions
      },
      footeNote: {
        className: joinNonEmptyStrings(
          CustomMadePageBS,
          "cost-delivery footnote"
        ),
        text: (
          <ul className="px-2">
            {_i18n.costAndDelivery.footeNote.map((item, i) => (
              <li
                key={i}
                className="pl-1"
                style={{ listStyleType: `"${item.TITLE}"` }}
              >
                {item.TEXT}
              </li>
            ))}
          </ul>
        )
      }
    },
    colorPreference: {
      lead: {
        header: _i18n.colorPreference.lead.TITLE,
        title: null,
        headerAs: "h2",
        text: escapeReactRoute(_i18n.colorPreference.lead.TEXT),
        imageOrientation: "right",
        md: 4,
        className: joinNonEmptyStrings(
          CustomMadePageBS,
          "color-preference-lead"
        ),
        img: _i18n.colorPreference.lead.IMG
          ? {
              src:
                imgParams.imgDir +
                "/pages/custom-made/".concat(_i18n.colorPreference.lead.IMG),
              importance: "high",
              cloudinary: imgParams.cloudinary,
              sizes: {
                mobilePortrait: 301,
                mobileLandscape: 466,
                tabletPortrait: 200,
                tabletLandscape: 280,
                desktop: 333
              },
              aspect: 1.3674,
              version: true,
              title: _i18n.colorPreference.lead.TITLE
            }
          : null
      },
      table: {
        visible: _i18n.colorPreference.table.visible,
        header: (_i18n.colorPreference.table.header || []).map(item => ({
          className: "w-50",
          children: item.TITLE
        })),
        rows: (_i18n.colorPreference.table.rows || []).map((item, i) =>
          item.columns.map((col, j) =>
            !i && !j ? { rowSpan: 6, children: col.TEXT } : col.TEXT
          )
        ),
        headVariant: "active",
        headerAlign: "center",
        textAlign: "left",
        className: joinNonEmptyStrings(
          CustomMadePageBS,
          "color-preference-table"
        ),
        bordered: true,
        borderless: true,
        hover: false,
        responsive: "md",
        small: false,
        striped: false,
        //variant: "light",
        //firstColAsHead: true,
        smallMediaBreakpoint: "smDown",
        defaultMediaBreakpoint: "smUp",
        caption: _i18n.colorPreference.table.CAPTION // https://getbootstrap.com/docs/4.5/content/tables/#captions
      }
    },

    links: {
      title: _i18n.links.header.TITLE,
      text: (
        <ul className="px-2">
          {_i18n.links.items.map((item, i) => {
            let children;

            if (item.URL) {
              children = escapeReactRoute(
                `<a href="${item.URL}">${item.TITLE}</a>`
              );
            } else {
              const match = /^\/?([^/]+)\/(.*)/.exec(item.CATEGORY_SLUG);
              let url;

              // the CATEGORY_SLUG has the format "/routeName/categorySlug"
              if (null !== match) {
                url = getRoutePathByRouteName(...match.slice(1));
              } else {
                // the CATEGORY_SLUG has the format "categorySlug"
                url = getRoutePathBySelectorType(
                  PRODUCT_SELECTOR_TYPE_CATEGORY,
                  item.CATEGORY_SLUG
                );
              }

              children = escapeReactRoute(`<a href="${url}">${item.TITLE}</a>`);
            }

            return <li key={i}>{children}</li>;
          })}
        </ul>
      ),
      titleAs: "h2",
      className: joinNonEmptyStrings(CustomMadePageBS, "links")
    }
  };
};
